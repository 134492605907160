<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'privacyPolicy',

    metaInfo: { title: 'Политика конфиденциальности в области обработки персональных данных' },

    extends: View,

    mixins: [
      LoadSections([
        'privacy',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'privacy',
      },
    },
  }
</script>
